import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { enumTiposUsuario } from '../enums/enumTiposUsuario';

import { IMenu, IMenuItem } from '../interfaces/IMenu';
import { IResultHttp } from '../interfaces/IResultHttp';

import { AgenciaTurismoModel } from '../models/agenciaTurismoModel';
import { ComissionadoModel } from '../models/comissionadoModel';
import { GuiaTurismoModel } from '../models/guiaTurismoModel';
import { UsuarioModel } from '../models/usuarioModel';

import { BaseService } from './base.service';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseService<UsuarioModel> {

  public loginSubject = new Subject<boolean>();

  // o observable abaixo serve para que quando um usuário abrir outra janela e
  // se logar com um usuário diferente, ao voltar na janela original vai fazer teste
  // ao navegar para outra página e comparar o conteúdo deste observable x localstorage.
  public uidUsuarioMenuSubject = new BehaviorSubject<string>('');

  public menu: Array<IMenu> = new Array<IMenu>();
  public menuAgencia: Array<IMenuItem> = new Array<IMenuItem>();
  public menuPerfil: Array<IMenuItem> = new Array<IMenuItem>();
  public menuAjuda: Array<IMenuItem> = [
    {
      icon: 'help_outline',
      label: 'Dúvidas Frequentes',
      url: `/AmbosGeral/ConsultaAssuntosDuvidas`
    },
    {
      icon: 'video_library',
      label: 'Tutoriais',
      url: `/AmbosGeral/Tutoriais`
    },
    {
      icon: 'assignment',
      label: 'Termos de Uso',
      url: `/AmbosGeral/Termo/UsoGuia`
    },
    {
      icon: 'description',
      label: 'Política Privacidade',
      url: `/AmbosGeral/Termo/Privacidade`
    },
    {
      icon: 'library_books',
      label: `Versão: ${environment.version}`,
      url: `/AmbosGeral/Home`
    }
  ];

  constructor(
    public override http: HttpService
  ) {
    super('usuario', http);
  }

  login(edEmailUsuario: string, dlSenhaUsuario: string): Promise<IResultHttp> {
    return this.http.post(`${environment.url_api}/usuario/login`, { edEmailUsuario, dlSenhaUsuario });
  }

  altSenha(edEmailUsuario: string, dlSenhaUsuario: string, dlNovaSenha: string, dlConfirmNovaSenha: string): Promise<IResultHttp> {
    return this.http.post(`${environment.url_api}/usuario/altSenha`, { edEmailUsuario, dlSenhaUsuario, dlNovaSenha, dlConfirmNovaSenha });
  }

  esqueciSenha(edEmailUsuario: string): Promise<IResultHttp> {
    return this.http.post(`${environment.url_api}/usuario/esqueciSenha`, { edEmailUsuario });
  }

  getEmail(edEmailUsuario: string): Promise<IResultHttp> {
    return this.http.get(`${environment.url_api}/usuario/email/${edEmailUsuario}`);
  }

  configureLogin(o: any): number {
    const { token, Usuario, GuiaTurismo, Comissionado } = o.data;
    let _usu = <UsuarioModel>Usuario;
    const _tpUsuario = _usu.tpUsuario;
    if (_tpUsuario == enumTiposUsuario.Cliente_2) {  //tipo cliente
      return _tpUsuario;
    } else {
      localStorage.setItem('passeioja:token', token);
      localStorage.setItem('passeioja:Usuario', JSON.stringify(Usuario));
      localStorage.setItem('passeioja:tpUsuario', _tpUsuario.toString());
      if (_tpUsuario == enumTiposUsuario.Guia_1) {
        const _guia = <GuiaTurismoModel>GuiaTurismo;
        localStorage.setItem('passeioja:GuiaTurismo', JSON.stringify(GuiaTurismo));
        if (Comissionado) {
          localStorage.setItem('passeioja:Comissionado', JSON.stringify(Comissionado));
        }
        localStorage.setItem('passeioja:flGuiaAdministrador', _guia.flGuiaAdministrador ? "true" : "false");
        if (_guia) {
          if (_guia.agenciaTurismo) {
            const _agencia = <AgenciaTurismoModel>_guia.agenciaTurismo;
            const _uidAgencia = _agencia.uid;
            localStorage.setItem('passeioja:uidAgencia', _uidAgencia);
          }
          this.carregarMenu(_tpUsuario, _usu, _guia.flGuiaAdministrador, Comissionado);
        }
      } else {
        if (_tpUsuario == enumTiposUsuario.Comissionado_4) {
          localStorage.setItem('passeioja:Comissionado', JSON.stringify(Comissionado));
        }
        this.carregarMenu(_tpUsuario, _usu);
      }
      this.loginSubject.next(this.isStaticLogged);
      return _tpUsuario;
    }
  }

  public carregarMenu(tpUsuario: number, usuario: UsuarioModel, flGuiaAdministrador?: boolean, comissionado?: ComissionadoModel) {
    this.limparMenu();
    this.uidUsuarioMenuSubject.next(usuario.uid);
    if (tpUsuario == enumTiposUsuario.Guia_1) {    // menu para Guia Turismo
      let _uidAgencia = localStorage.getItem('passeioja:uidAgencia')
      let _guiaTurismo: GuiaTurismoModel = JSON.parse(localStorage.getItem('passeioja:GuiaTurismo'));
      // Monta menu perfil
      this.menuPerfil.push({ icon: 'person', label: 'Editar perfil', url: `/AmbosCad/CadastroUsuario/${_guiaTurismo.uid}` });
      this.menuPerfil.push({ icon: 'lock', label: 'Trocar Senha', url: `/AmbosGeral/TrocaSenha/${_guiaTurismo.uid}` });
      this.menuPerfil.push({ icon: 'exit_to_app', label: 'Sair', url: '/AmbosGeral/Sair/Confirm' });
      if (_guiaTurismo.flGuiaAprovadoAgencia) {
        this.menu.push({
          group: 'Cadastros',
          items: [
            { icon: 'settings', label: 'Itens Padrões', url: `/AmbosCad/ItensPadroesAgencia/${_guiaTurismo.agenciaTurismo.uid}/${_guiaTurismo.agenciaTurismo.nmFantasiaAgencia}` },
            { icon: 'insert_photo', label: 'Passeios', url: `/AmbosCad/PasseiosAgencia/${_guiaTurismo.agenciaTurismo.uid}` },
            { icon: 'card_giftcard', label: 'Cupons de Desconto', url: `/AmbosCad/Cupons/${_guiaTurismo.agenciaTurismo.uid}/tudo/default/1/default/default` },
          ]
        });
        if (flGuiaAdministrador) {
          this.menu.push({
            group: 'Gerenciamento',
            items: [
              { icon: 'insert_chart_outlined', label: `Estatística Pedidos`, url: `/AmbosGeren/EstatisticaPedidos/${_guiaTurismo.agenciaTurismo.uid}/NA/NA` },
              { icon: 'shopping_cart', label: 'Lista Pedidos', url: `/AmbosGeren/PedidosAgencia/${_guiaTurismo.agenciaTurismo.uid}/default/default/tudo/default/1/default/default` },
              { icon: 'monetization_on', label: 'Extrato Reservas', url: `/AmbosGeren/LancamentosAgencia/${_guiaTurismo.agenciaTurismo.uid}/1/default/default/tudo/default/1/default/default` },
              { icon: 'attach_money', label: 'Lançamentos Extras', url: `/AmbosGeren/LancamentosAgencia/${_guiaTurismo.agenciaTurismo.uid}/2/default/default/tudo/default/1/default/default` },
              // Desisti de testar e implantar essa funcionalidade para as agencias
              // { icon: 'date_range', label: 'Agenda Passeios', url: `/AmbosGeren/AgendaPasseios/${_guiaTurismo.agenciaTurismo.uid}/nenhuma/2/tudo/default/1/default/default` },
              { icon: 'undo', label: 'Devoluções Pendentes', url: `/AmbosGeren/PedidosDevolucaoPendente/${_guiaTurismo.agenciaTurismo.uid}/tudo/default/1/default/default` },
              { icon: 'history', label: 'Histórico Devoluções', url: `/AmbosGeren/PedidosValoresDevolvidos/${_guiaTurismo.agenciaTurismo.uid}/default/default/tudo/default/1/default/default` },
            ]
          });
          if (comissionado && comissionado.uid) {
            this.menu.push({
              group: 'Comissionamento',
              items: [
                { icon: 'business', label: 'Lista Agências', url: `/AmbosGeren/ComissionadoParticipacoes/${comissionado.uid}/${comissionado.nmComissionado}/agencias/tudo/default/1/default/default` },
                { icon: 'all_out', label: 'Lista Campanhas', url: `/AmbosGeren/ComissionadoParticipacoes/${comissionado.uid}/${comissionado.nmComissionado}/campanhas/tudo/default/1/default/default` },
                { icon: 'monetization_on', label: 'Extrato Comissões', url: `/AmbosGeren/LancamentosComissionado/${comissionado.uid}/${comissionado.nmComissionado}/default/default/tudo/8/1/default/default` },
              ]
            });
          }
          // Monta menu Agência
          this.menuAgencia.push({ icon: 'assignment_ind', label: 'Guias da Agência', url: `/AmbosCad/GuiasAgencia/${_guiaTurismo.agenciaTurismo.uid}` });
          this.menuAgencia.push({ icon: 'business', label: 'Agência', url: `/AmbosCad/AgenciaTurismo/${_guiaTurismo.agenciaTurismo.uid}` });
        }
      } else {
        if (!_guiaTurismo.agenciaTurismo) {
          this.menu.push({
            group: 'Cadastro',
            items: [
              { icon: 'business', label: 'Registrar agência', url: `/AmbosCad/CadastroAgencia/new` }
            ]
          });
        } else {  // guia ainda não aprovado
          this.menu.push({
            group: 'Sair',
            items: [
              { icon: 'exit_to_app', label: 'Sair', url: '/AmbosGeral/Sair/Direto' },
            ]
          });
        }
      }
    } else {
      if (tpUsuario == enumTiposUsuario.Admin_3) {    // menu para Admin
        let _usuario: UsuarioModel = JSON.parse(localStorage.getItem('passeioja:Usuario'));
        // Monta menu perfil
        this.menuPerfil.push({ icon: 'lock', label: 'Trocar Senha', url: `/AmbosGeral/TrocaSenha/${_usuario.uid}` });
        this.menuPerfil.push({ icon: 'exit_to_app', label: 'Sair', url: '/AmbosGeral/Sair/Confirm' });
        if (usuario.flAdminFinanceiro) {
          this.menu.push({
            group: 'Cadastros',
            items: [
              { icon: 'bookmark', label: 'Categorias', url: '/AdminCad/Categorias' },
              { icon: 'insert_photo', label: 'Regiões Turisticas', url: '/AdminCad/RegioesTuristicas/tudo/default/1/default/default' },
              { icon: 'help', label: 'Perguntas Frequentes', url: '/AdminCad/AssuntosDuvidas' },
              { icon: 'bookmark_border', label: 'Itens Inclusão Passeio', url: '/AdminCad/ItensInclusao' },
              { icon: 'publish', label: 'Publicações', url: '/AdminCad/Publicacoes' },
              { icon: 'announcement', label: 'Destaques', url: '/AdminCad/Destaques/tudo/default/1/default/default' },
              { icon: 'all_out', label: 'Campanhas', url: '/AdminCad/Campanhas/tudo/default/1/default/default' },
              { icon: 'monetization_on', label: 'Comissionados', url: '/AdminCad/Comissionados/tudo/default/1/default/default' },
              { icon: 'business', label: 'Agências de Turismo', url: '/AdminGeren/AgenciasTurismo/BR/vazio/tudo/default/1/default/default' },
            ]
          });
        } else {
          // para suporte não aparece Comissionados
          this.menu.push({
            group: 'Cadastros',
            items: [
              { icon: 'bookmark', label: 'Categorias', url: '/AdminCad/Categorias' },
              { icon: 'insert_photo', label: 'Regiões Turisticas', url: '/AdminCad/RegioesTuristicas/tudo/default/1/default/default' },
              { icon: 'help', label: 'Perguntas Frequentes', url: '/AdminCad/AssuntosDuvidas' },
              { icon: 'bookmark_border', label: 'Itens Inclusão Passeio', url: '/AdminCad/ItensInclusao' },
              { icon: 'publish', label: 'Publicações', url: '/AdminCad/Publicacoes' },
              { icon: 'announcement', label: 'Destaques', url: '/AdminCad/Destaques/tudo/default/1/default/default' },
              { icon: 'all_out', label: 'Campanhas', url: '/AdminCad/Campanhas/tudo/default/1/default/default' },
              { icon: 'business', label: 'Agências de Turismo', url: '/AdminGeren/AgenciasTurismo/BR/vazio/tudo/default/1/default/default' },
            ]
          });
        }
        this.menu.push({
          group: 'Suporte',
          items: [
            { icon: 'local_offer', label: `Acompanhamento Agências`, url: `/AdminGeren/AcompanhamentoAgencias/tudo/default/1/default/default` },
            { icon: 'check_circle', label: 'Aprovação Passeios', url: '/AdminGeren/AprovacaoPasseios' },
            { icon: 'search', label: 'Busca Passeios', url: '/AdminGeren/BuscaPasseios/tudo/default/1/default/default' },
            { icon: 'date_range', label: 'Agenda Passeios', url: '/AmbosGeren/AgendaPasseios/admin/nenhuma/2/tudo/default/1/default/default' },
            { icon: 'card_giftcard', label: 'Cupons de Desconto', url: '/AmbosCad/Cupons/admin/tudo/default/1/default/default' },
            { icon: 'thumb_down_alt', label: `Alerta Avaliações`, url: `/AdminGeren/AlertaAvaliacoes/tudo/default/1/default/default` },
            { icon: 'shopping_cart', label: `Pedidos Recentes`, url: `/AdminGeren/ListaPedidosRecentes/tudo/default/1/default/default` },
            { icon: 'cancel_presentation', label: `Consulta Cancelamentos`, url: `/AdminGeren/ConsultaCancelamentosReagendamentos/C/default/default/tudo/default/1/default/default` },
            { icon: 'calendar_today', label: `Consulta Reagendamentos`, url: `/AdminGeren/ConsultaCancelamentosReagendamentos/R/default/default/tudo/default/1/default/default` },
            { icon: 'supervisor_account', label: `Lista Usuários`, url: `/AdminGeren/ListaUsuarios/2/default/default/tudo/default/1/default/default` },
            { icon: 'account_box', label: `Consulta Usuário`, url: `/AdminGeren/ConsultaUsuario/vazio` },
            { icon: 'trending_up', label: `Totais Acessos`, url: `/AdminGeren/TotaisAcessos` },
            { icon: 'language', label: `Acompanhamento Acessos`, url: `/AdminGeren/AcompanhamentoAcessos/default/tudo/default/1/default/default` },
            { icon: 'undo', label: 'Devoluções Pendentes', url: `/AmbosGeren/PedidosDevolucaoPendente/todas/tudo/default/1/default/default` },
            { icon: 'history', label: 'Histórico Devoluções', url: `/AmbosGeren/PedidosValoresDevolvidos/todas/default/default/tudo/default/1/default/default` },
            { icon: 'report_problem', label: 'Log Transações', url: `/AdminGeren/ListaLogProcessoGatewayComponent` },
            { icon: 'assignment_late', label: 'Acompanhamento Pendências', url: `/AdminGeren/AcompanhamentoPendencias/vencido/tudo/default/1/default/default` }
          ]
        });
        if (usuario.flAdminFinanceiro) {
          this.menu.push({
            group: 'Gerenciamento',
            items: [
              { icon: 'list_alt', label: 'Totais por Região', url: '/AdminGeren/TotaisPasseiosRegioes/default/0/tudo/default/1/default/default' },
              { icon: 'insert_chart', label: 'Estatística Pedidos', url: '/AmbosGeren/EstatisticaPedidos/admin/NA/NA' },
              { icon: 'monetization_on', label: 'Extrato Reservas', url: `/AdminGeren/LancamentosPasseioJa/1/default/default/tudo/default/1/default/default` },
              { icon: 'attach_money', label: 'Lançamentos Extras', url: `/AdminGeren/LancamentosPasseioJa/2/default/default/tudo/default/1/default/default` },
              { icon: 'receipt', label: 'Lista Faturas', url: `/AdminGeren/ListaFaturas/default/default/tudo/default/1/default/default` },
              { icon: 'map', label: 'Gerar Sitemap', url: `/AdminGeren/GerarSitemap` },
            ]
          });
        }
        this.menu.push({
          group: 'Sair',
          items: [
            { icon: 'exit_to_app', label: 'Sair', url: '/AmbosGeral/Sair/Confirm' },
          ]
        });
      } else {
        if (tpUsuario == enumTiposUsuario.Comissionado_4) {    // menu para Comissionado que nao seja a propria agencia
          let _comissionado: ComissionadoModel = JSON.parse(localStorage.getItem('passeioja:Comissionado'));
          this.menu.push({
            group: 'Gerenciamento',
            items: [
              { icon: 'business', label: 'Lista Agências', url: `/AmbosGeren/ComissionadoParticipacoes/${_comissionado.uid}/${_comissionado.nmComissionado}/agencias/tudo/default/1/default/default` },
              { icon: 'all_out', label: 'Lista Campanhas', url: `/AmbosGeren/ComissionadoParticipacoes/${_comissionado.uid}/${_comissionado.nmComissionado}/campanhas/tudo/default/1/default/default` },
              { icon: 'monetization_on', label: 'Extrato Comissões', url: `/AmbosGeren/LancamentosComissionado/${_comissionado.uid}/${_comissionado.nmComissionado}/default/default/tudo/8/1/default/default` },
            ]
          });
          this.menu.push({
            group: 'Perfil',
            items: [
              { icon: 'lock', label: 'Trocar Senha', url: `/AmbosGeral/TrocaSenha/${_comissionado.uid}` },
              { icon: 'exit_to_app', label: 'Sair', url: '/AmbosGeral/Sair/Confirm' },
            ]
          });
        }
      }
    }
  }

  public carregarMenuSair() {
    this.menu.length = 0;
    setTimeout((a: any) => {
      this.menu.push({
        group: 'Sair',
        items: [
          { icon: 'exit_to_app', label: 'Sair', url: '/AmbosGeral/Sair/Direto' },
        ]
      });
    }, 100, []);
  }

  public carregarMenuInstitucional() {
    this.menu.length = 0;
    setTimeout((a: any) => {
      this.menu.push({
        group: 'Menu',
        items: [
          { label: "Passeios", url: `${environment.urlPasseioJa}`, icon: "", cdNavegacao: 'Ext' },
          { label: "Serviços", url: "about", icon: "", cdNavegacao: 'Tag' },
          { label: "Vantagens", url: "vantagens", icon: "", cdNavegacao: 'Tag' },
          { label: "Tarifas", url: "important", icon: "", cdNavegacao: 'Tag' },
          { label: "FAQ", url: "faq", icon: "", cdNavegacao: 'Tag' },
          { label: "Contato", url: "contato", icon: "", cdNavegacao: 'Tag' },
          // { label: "Saiba Mais", url: "SaibaMais", icon: "", cdNavegacao: 'Dialog' },
          { label: "Login", url: "/AmbosGeral/Login", icon: "", cdNavegacao: 'Rota' },
          { label: "Cadastre-se", url: "/AmbosCad/CadastroUsuario/new", icon: "", cdNavegacao: 'Rota' }
        ]
      });
    }, 100, []);
  }

  public limparMenu() {
    this.menu.length = 0;
    this.menuAgencia.length = 0;
    this.menuPerfil.length = 0;
    this.uidUsuarioMenuSubject.next('');
  }

  public limparLocalStorage() {
    if (localStorage.getItem('passeioja:token')) {
      localStorage.removeItem('passeioja:token');
    }
    if (localStorage.getItem('passeioja:Usuario')) {
      localStorage.removeItem('passeioja:Usuario');
    }
    if (localStorage.getItem('passeioja:GuiaTurismo')) {
      localStorage.removeItem('passeioja:GuiaTurismo');
    }
    if (localStorage.getItem('passeioja:Comissionado')) {
      localStorage.removeItem('passeioja:Comissionado');
    }
    if (localStorage.getItem('passeioja:uidAgencia')) {
      localStorage.removeItem('passeioja:uidAgencia');
    }
    if (localStorage.getItem('passeioja:tpUsuario')) {
      localStorage.removeItem('passeioja:tpUsuario');
    }
    if (localStorage.getItem('passeioja:flGuiaAdministrador')) {
      localStorage.removeItem('passeioja:flGuiaAdministrador');
    }
    this.loginSubject.next(this.isStaticLogged);
  }

  get isLogged(): Observable<boolean> {
    return this.loginSubject.asObservable();
  }

  get isStaticLogged(): boolean {
    return !!localStorage.getItem('passeioja:token');
  }

  observableUidUsuarioLogged(): Observable<string> {
    return this.uidUsuarioMenuSubject.asObservable().pipe(distinctUntilChanged());
  }


  static get token(): string {
    return localStorage.getItem('passeioja:token');
  }
}
